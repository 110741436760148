import React from "react"
import Layout from "../../components/Layout"

export default function Projects() {
  return (
    <Layout>
      <section>
        <h2>Portfolio</h2>
        <h3>Projects & Websites I've Created</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </section>
    </Layout>
  )
}
